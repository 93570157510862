<template>
    <sort-list
        :Flag="false"
        :searchs="true"
        v-model="list"
        :defaultQuery="defaultQuery"
        :sortKey="sortKey"
        ref="list"
        url="/policyLaw/all"
    >
        <!-- <div class="border"></div> -->
        <div class="science_list">
            <div v-for="item in list" :key="item.id" class="science_list_con">
                <router-link
                    :to="{
                        name: 'newsDetail',
                        query: {
                            id: item.id
                        }
                    }"
                    class="patent"
                >
                    <div class="science_list_con_one">
                        <img :src="item.img" alt="" class="science_list_con_img" />
                        <div class="science_list_con_text">
                            <div class="science_list_con_title">{{ item.name }}</div>
                            <div class="science_list_con_content">{{ item.name }}</div>
                            <div class="science_list_con_time">{{ formatTime(item.issuedAt, 'YYYY.MM.DD') }}</div>
                        </div>
                    </div>
                    <div class="science_list_con_two">详情<i class="el-icon-arrow-right"></i></div>
                    <!-- <news-row :key="item.id" :info="item"></news-row> -->
                </router-link>
            </div>
        </div>
    </sort-list>
</template>

<script>
import newsRow from './list/newsRow.vue';
import SortList from './page/SortList.vue';
export default {
    components: { SortList, newsRow },
    created() {
        this.init();
    },
    data() {
        return {
            list: [],
            defaultQuery: {
                category: 'NEWS',
                typeSettingId: 325
            },
            sortKey: [
                    {
                        name: '发布时间',
                        key: 'issuedAt'
                    }
                ]
        };
    },
    computed: {},
    methods: {
        init() {
            this.$nextTick(() => {
                this.$refs.list.init();
            });
        }
    }
};
</script>

<style lang="less" scoped>
.border {
    border-bottom: 20px solid @bg;
}
.science_list {
    padding: 20px;
    .science_list_con {
        margin-bottom: 20px;
        .patent {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .science_list_con_one {
            display: flex;
        }
        .science_list_con_text {
            width: 619px;
        }
        .science_list_con_img {
            width: 138px;
            height: 98px;
            margin-right: 16px;
        }
        .science_list_con_title {
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            line-height: 20px;
            margin-bottom: 12px;
        }
        .science_list_con_content {
            font-size: 13px;
            font-weight: 400;
            color: #939599;
            line-height: 18px;
            margin-bottom: 10px;
        }
        .science_list_con_time {
            font-size: 14px;
            font-weight: 400;
            color: #939599;
            line-height: 20px;
        }
        .science_list_con_two {
            width: 66px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #979797;
            font-size: 14px;
            color: #939599;
            line-height: 30px;
            text-align: center;
        }
        &:hover {
            .science_list_con_title {
                color: #01a041;
            }
            .science_list_con_content {
                color: #01a041;
            }
            .science_list_con_time {
                color: #01a041;
            }
            .science_list_con_two {
                border: 1px solid #01a041;
                color: #01a041;
            }
        }
    }
}
</style>
