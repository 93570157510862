<template>
    <sort-list
        :Flag="false"
        :searchs="true"
        v-model="list"
        :defaultQuery="defaultQuery"
        :sortKey="sortKey"
        :newsList="newsList"
        ref="list"
        url="/policyLaw/all"
    >
        <!-- <div class="border"></div> -->
        <div v-for="item in list" :key="item.id">
            <news-row :key="item.id" :info="item"></news-row>
        </div>
    </sort-list>
</template>

<script>
import newsRow from './list/newsRow.vue';
import SortList from './page/SortList.vue';
export default {
    components: { SortList, newsRow },
    created() {
        this.init();
    },
    data() {
        return {
            list: [],
            defaultQuery: {
                category: 'NEWS',
                typeSettingId: 3240
            },
            sortKey: [
                {
                    name: '发布时间',
                    key: 'issuedAt'
                }
            ],
            newsList: true
        };
    },
    computed: {},
    methods: {
        init() {
            this.$nextTick(() => {
                this.$refs.list.init();
            });
        }
    }
};
</script>

<style lang="less" scoped>
.border {
    border-bottom: 20px solid @bg;
}
</style>
