<template>
    <bread-page>
        <menu-page>
            <div class="menu-left" slot="menuList">
                <div class="menu-left-con">
                    <img src="../../assets/gongxu_icon_xuqiuxinxi@3x.png" alt="" />
                    <div class="menu-left-con-title">知产咨询动态</div>
                </div>
                <el-menu :default-active="flag" @select="handleSelect">
                    <el-menu-item index="1">平台动态</el-menu-item>
                    <el-menu-item index="2">通知公告</el-menu-item>
                    <el-menu-item index="3">知产百科</el-menu-item>
                </el-menu>
            </div>

            <div class="main">
                <div v-if="flag == 1">
                    <news-dynamic></news-dynamic>
                </div>
                <div v-else-if="flag == 2">
                    <news-field></news-field>
                </div>
                <div v-else>
                    <news-dynamics></news-dynamics>
                </div>
            </div>
        </menu-page>
    </bread-page>
</template>

<script>
import { mapState } from 'vuex';
import BreadPage from '../../components/page/BreadPage.vue';
import MenuPage from '../../components/page/MenuPage.vue';
import newsDynamic from '../../components/newsDynamic.vue';
import newsField from '../../components/newsField.vue';
import newsDynamics from '../../components/newsDynamics.vue';
export default {
    components: { BreadPage, MenuPage, newsField, newsDynamic, newsDynamics },
    data() {
        return {
            flag: 1
        };
    },
    computed: {
        ...mapState(['adminleftnavnum'])
    },
    watch: {
        '$store.state.flag': {
            handler(val) {
                if(val){
                    this.flag = this.$store.state.flag
                }
            },
            immediate: true,
        }
    },
    methods: {
        handleSelect(key) {
            this.flag = key;
            this.$router.push('/newsList?flag=' + key);
        }
    },
    created() {
        if (this.$route.query.flag) {
            this.handleSelect(this.$route.query.flag);
        }
    }
};
</script>

<style lang="less" scoped>
.bread-page {
    background: linear-gradient(180deg, #009e40 0%, #fafafa 100%);
    background-size: 100% 300px;
    background-repeat: no-repeat;
}
.appContainer {
    background-color: #fafafa;
}
.menu-left {
    // min-height: 600px;
    background-color: #fff;
    .menu-left-con {
        height: 60px;
        background: #01a041;
        display: flex;
        padding-left: 34px;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        .menu-left-con-title {
            font-size: 16px;
        }
        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }
}
.main {
    min-height: 900px;
    margin-top: -20px;
    flex-grow: 1;
    .flex-col();
}
/deep/ .el-menu-item {
    // text-align: center;
    // padding-right: 60px;
    padding-left: 68px !important;
}
/deep/ .el-menu > .el-menu-item {
    &.is-active {
        background: linear-gradient(90deg, rgba(1, 160, 65, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
        color: #01a041;
        position: relative;
    }
    &.is-active::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60px;
        width: 1px;
        background-color: #01a041;
    }
}
</style>
